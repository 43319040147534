import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

// 使用插件
Vue.use(VueRouter);

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function(location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => {}, () => {});
    }
};

VueRouter.prototype.replace = function(location, resolve, reject) {
    if (resolve && reject) {
        // 修正此处的调用错误
        originReplace.call(this, location, resolve, reject);
    } else {
        originReplace.call(this, location, () => {}, () => {});
    }
};

// 配置路由
let router = new VueRouter({
    // 配置路由
    routes,
    // 滚动行为
    scrollBehavior(to, from, savePosition) {
        // 返回的 y = 0，代表滚动条在最上方
        return { y: 0 };
    }
});

export default router;