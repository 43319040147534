<template>
  <div id="body">
    <div class="newRight">
      <span class="tree" @click="changeShow">&#9776;</span>
      <div class="newDropdown" v-show="isShow">
        <div class="left-close"></div>
        <span class="close" @click="closeDorpDown">x</span>
        <div class="right-tree">
          <el-tree
            :data="data"
            :props="defaultProps"
            accordion
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
      </div>
    </div>
    <div class="headerNav">
      <div class="left">
        <img src="./images/logo上水印 拷贝.png" alt="#" />
        易添信息
      </div>
      <div class="right">
        <ul class="clearfix">
          <li class="dropdown">
            <router-link to="/home">网站首页</router-link>
          </li>
          <li class="dropdown">
            <router-link to="/aboutyitian">关于易添</router-link>
            <span class="downward"></span>
            <ul class="dropdown-content">
              <li><router-link to="/profile">公司简介</router-link></li>
              <li><router-link to="/honor">企业荣誉</router-link></li>
              <li><router-link to="/customer">客户案例</router-link></li>
            </ul>
          </li>
          <li class="dropdown">
            <router-link to="/productshow">产品展示</router-link>
            <span class="downward"></span>
            <ul class="dropdown-content product-dropdown">
              <li><router-link to="/beidou">北斗赛事保障系统</router-link></li>
              <li>
                <router-link to="/chongdian">一站式充电桩系统</router-link>
              </li>
              <li>
                <router-link to="/qiti">工业气体智能管理平台</router-link>
              </li>
            </ul>
          </li>
          <li class="dropdown">
            <router-link to="/newsdynamics">新闻动态</router-link>
            <span class="downward"></span>
            <ul class="dropdown-content">
              <li><router-link to="/companynews">公司动态</router-link></li>
              <li><router-link to="/solution">行业资讯</router-link></li>
            </ul>
          </li>
          <li class="dropdown">
            <router-link to="/contactour">联系我们</router-link>
            <span class="downward"></span>
            <ul class="dropdown-content">
              <li><router-link to="/contactmethod">联系方式</router-link></li>
              <li><router-link to="/contactonline">在线留言</router-link></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isShow: false,
      data: [
        {
          label: "网站首页",
        },
        {
          label: "关于易添",
          children: [
            {
              label: "公司简介",
            },
            {
              label: "企业荣誉",
            },
            {
              label: "客户案例",
            },
          ],
        },
        {
          label: "产品展示",
          children: [
            {
              label: "北斗赛事保障系统",
            },
            {
              label: "一站式充电桩系统",
            },
            {
              label: "工业气体智能管理平台",
            },
          ],
        },
        {
          label: "新闻动态",
          children: [
            {
              label: "公司动态",
            },
            {
              label: "行业资讯",
            },
          ],
        },
        {
          label: "联系我们",
          children: [
            {
              label: "联系方式",
            },
            {
              label: "在线留言",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    changeShow() {
      this.isShow = !this.isShow;
    },
    closeDorpDown() {
      this.isShow = false;
    },
    handleNodeClick(data) {
      // 根据节点标签确定跳转路径
      let path = "";
      switch (data.label) {
        case "网站首页":
          path = "/home";
          break;
        case "关于易添":
          path = "/aboutyitian";
          break;
        case "公司简介":
          path = "/profile";
          break;
        case "企业荣誉":
          path = "/honor";
          break;
        case "客户案例":
          path = "/customer";
          break;
        case "产品展示":
          path = "/productshow";
          break;
        case "北斗赛事保障系统":
          path = "/beidou";
          break;
        case "一站式充电桩系统":
          path = "/chongdian";
          break;
        case "工业气体智能管理平台":
          path = "/qiti";
          break;
        case "新闻动态":
          path = "/newsdynamics";
          break;
        case "公司动态":
          path = "/companynews";
          break;
        case "行业资讯":
          path = "/solution";
          break;
        case "联系我们":
          path = "/contactour";
          break;
        case "联系方式":
          path = "/contactmethod";
          break;
        case "在线留言":
          path = "/contactonline";
          break;
        default:
          break;
      }
      // 如果路径存在，进行页面跳转
      if (path) {
        this.$router.push(path);
        // 跳转后关闭手风琴菜单
        this.closeDorpDown();
      }
    },
  },
};
</script>
<style scoped>
.headerNav {
  background-color: #1f749f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(221, 219, 219);
  height: 4.5rem;
  padding-left: 6vw;
  opacity: 0.9;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}
.left {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.left img {
  width: 4.3rem;
}
.right {
  padding-right: 6vw;
}
.right ul .dropdown {
  float: left;
  font-size: 1rem;
  height: 4.5rem;
  line-height: 4.5rem;
  width: 7rem;
  text-align: center;
  position: relative;
}
.right ul .dropdown a {
  color: rgb(221, 219, 219);
}
.right ul .dropdown:hover {
  background-color: #4484a4;
}
.newRight {
  display: none;
  position: fixed;
  top: 1rem; /* 使.newRight固定在顶部 */
  right: 0; /* 靠右显示 */
  z-index: 1002; /* 确保在导航栏之上 */
}
.newRight .tree {
  font-size: 1.8rem;
  cursor: pointer;
  padding-right: 4vw;
  color: #f9f9f9;
  font-weight: 600;
  position: relative; /* 确保.tree有定位，以便于newDropdown定位 */
  z-index: 1003; /* 确保.tree在newDropdown之上 */
}
.newDropdown {
  overflow-y: hidden;
  width: 13rem;
  max-height: 15rem;
  background-color: white;
  display: flex;
  gap: 0.5rem;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1002;
}
.close {
  font-size: 1rem;
  color: rgb(151, 151, 151);
  font-size: 1.1rem;
  cursor: pointer;
}
.downward {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.4rem;
  vertical-align: middle;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.dropdown-content li {
  height: auto;
  line-height: normal;
  width: auto;
  margin: 0;
  padding: 0;
  text-align: left;
}
.dropdown-content li a {
  color: rgb(82, 80, 80) !important;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.dropdown-content li a:hover {
  color: #1f749f !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.product-dropdown {
  min-width: 13rem;
}
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

#menu-toggle {
  display: none;
}
::v-deep .el-tree {
  width: 100%;
  height: 100%;
  overflow: auto;
}
@media (max-width: 844px) {
  .right {
    display: none;
  }
  .newRight {
    display: block;
  }
}
</style>