import { reqGetHonor } from "@/api"
const state = {
    honor:[]
}
const actions = {
    async getHonor({commit}){
        let result = await reqGetHonor();
        if(result.code == 200){
            commit("GRTHONOR",result.data)
            return 'ok'
        }else{
            return Promise.reject(new Error('fail'));
        }
    }
}
const mutations = {
    GRTHONOR(state,honor){
        state.honor = honor;
    }
}
const getters = {

}

export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}