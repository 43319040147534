import { reqGetSolution } from "@/api"
const state = {
    solution:[]
}
const actions = {
    async getSolution({commit}){
        let result = await reqGetSolution();
        if(result.code == 200){
            commit("GETSOLUTION",result.data)
            return 'ok'
        }else{
            return Promise.reject(new Error('fail'));
        }
    }
}
const mutations = {
    GETSOLUTION(state,solution){
        state.solution = solution;
    }
}
const getters = {

}

export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}