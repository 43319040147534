import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Header from './components/Header'
import Footer from './components/Footer'
Vue.component(Header.name,Header)
Vue.component(Footer.name,Footer)


import store from './store'
import '@/mock/mockServe'

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
