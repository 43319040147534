// 对于axios进行二次封装
import axios from "axios";
// 引入进度条
import nProgress from "nprogress";
// 引入进度条的样式
import 'nprogress/nprogress.css'

const requests = axios.create({
    baseURL:'/mock',
    timeout:5000,
});
requests.interceptors.request.use((config)=>{
    nProgress.start();
    return config;
});
// 响应拦截器
requests.interceptors.response.use((res)=>{
    nProgress.done();
    return res.data;
},(error)=>{
    return Promise.reject(new error('faile'));
})


export default requests;


