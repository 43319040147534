export default [
  {
    path: '/home',
    component: () => import('../pages/Home'),
    name: 'Home'
  },
  {
    path: '/aboutyitian',
    component: () => import('../pages/Company/Aboutyitian'),
    name: 'aboutyitian'
  },
  {
    path: '/honor',
    component: () => import('../pages/Company/Honor'),
    name: 'Honor'
  },
  {
    path: '/profile',
    component: () => import('../pages/Company/Profile'),
    name: 'Profile'
  },
  {
    path: '/contactour',
    component: () => import('../pages/Contact/ContactOur'),
    name: 'ContactOur'
  },
  {
    path: '/contactmethod',
    component: () => import('../pages/Contact/ContactMethod'),
    name: 'ContactMethod'
  },
  {
    path: '/contactonline',
    component: () => import('../pages/Contact/ContactOnline'),
    name: 'ContactOnline'
  },
  {
    path: '/productshow',
    component: () => import('../pages/Product/ProductShow'),
    name: 'ProductShow'
  },
  {
    path: '/newsdynamics',
    component: () => import('../pages/News/NewsDynamics'),
    name: 'NewsDynamics'
  },
  {
    path: '/companynews',
    component: () => import('../pages/News/CompanyNews'),
    name: 'CompanyNews'
  },
  {
    path: '/solution',
    component: () => import('../pages/News/Solution'),
    name: 'Solution'
  },
  {
    path: '/customer',
    component: () => import('../pages/Company/Customer'),
    name: 'Customer'
  },
  {
    path: '/beidou',
    component: () => import('../pages/Product/BeiDou'),
    name: 'BeiDou'
  },
  {
    path: '/chongdian',
    component: () => import('../pages/Product/ChongDian'),
    name: 'ChongDian'
  },
  {
    path: '/qiti',
    component: () => import('../pages/Product/QiTi'),
    name: 'QiTi'
  },
  {
    path: '/companydetail/:id',
    component: () => import('../pages/NewsDetail/CompanyDetail'),
    name: 'CompanyDetail'
  },
  {
    path: '/dynamicsdetail/:id',
    component: () => import('../pages/NewsDetail/DynamicsDetail'),
    name: 'DynamicsDetail'
  },
  {
    path: '/solutiondetail/:id',
    component: () => import('../pages/NewsDetail/SolutionDetail'),
    name: 'SolutionDetail'
  },
  {
    path: '*',
    redirect: '/home'
  }
];