import { reqGetCompany } from "@/api"
const state = {
    company:[]
}
const actions = {
    async getCompany({commit}){
        let result = await reqGetCompany();
        if(result.code == 200){
            commit("GETCOMPANY",result.data)
            return 'ok'
        }else{
            return Promise.reject(new Error('fail'));
        }
    }
}
const mutations = {
    GETCOMPANY(state,company){
        state.company = company;
    }
}
const getters = {

}

export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}