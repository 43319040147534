// 先引入mockjs模块
import Mock from "mockjs";
import honor from './honor.json'
import company from './company.json'
import dynamics from './dynamics.json'
import solution from './solution.json'

Mock.mock("/mock/honor",{code:200,data:honor});//模拟企业荣誉页面展示的荣誉的数据
Mock.mock("/mock/company",{code:200,data:company});//模拟公司动态页面展示的动态的数据
Mock.mock("/mock/dynamics",{code:200,data:dynamics});//模拟新闻动态页面展示的动态的数据
Mock.mock("/mock/solution",{code:200,data:solution});//模拟行业资讯页面展示的动态的数据

