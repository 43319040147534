import Vue from 'vue';
import Vuex from 'vuex';
// 需要使用插件一次
Vue.use(Vuex);
// 引入小仓库
import honor from './Honor'
import company from './Company'
import dynamics from './Dynamics'
import solution from './Solution'
// 对外暴露Store类的一个实例
export default new Vuex.Store({
   modules:{
    honor,
    company,
    dynamics,
    solution,
   }
})
